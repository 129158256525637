import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
const AsyncHome = asyncComponent(() => import("./containers/Home"));


export default ({ childProps }) =>
  <Switch>
    <Route
      path="/"
      exact
      component={AsyncHome}
      props={childProps}
    />
  </Switch>
  ;